import { createTheme } from "@mui/material";

export const defaultTheme = createTheme({

    //Primary colors for the project.
        //ex.
        //bgcolor: theme.palette.primary.main 
    palette: {
        //We can change these colors as needed. 
        primary: {
            main: '#0004ff', //Blue
            light: '#eff538', //Yellow
            dark: '#000000', //Black 
            contrastText: '#ffffff', //White
        },
        //Can add more colors into secondary if we need them
        secondary: {
            main: '#000000', //Black but dont use
        },
    },
});
