import React, {useState, useEffect} from 'react';
import NavBar from '../Components/navbar';
import Box from '@mui/material/Box';
import SearchBar from '../Components/searchbar';
import { HomeBackground } from '../Components/homebackground';
import Typography from '@mui/material/Typography';

export default function WatchList() {
/*
    const [list, setList] = useState(null);

    useEffect(() => {
        //Get user's watchlist
        //Ex.
        //getList().then(data => setList(data));
    }, []);

    if(list === null) {
        return <h1><strong>Loading...</strong></h1>
    }

    else if(list.length === 0) {
        return <h1><strong>NOTHING ON YOUR WATCHLIST</strong></h1>
    }
*/
  return (
    <HomeBackground>
      <NavBar/>
      <SearchBar/>
      <Box component="div" sx={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
        <Typography variant="h4" sx={{fontWeight:"bold"}}>NOTHING ON YOUR WATCHLIST</Typography>
      </Box>
    </HomeBackground>
  );
}