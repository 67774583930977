import React from 'react';
import NavBar from '../Components/navbar';
import Box from '@mui/material/Box';
import SearchBar from '../Components/searchbar';
import { HomeBackground } from '../Components/homebackground';
import Typography from '@mui/material/Typography';

export default function Anomalies() {

  return (
    <HomeBackground>
      <NavBar/>
      <SearchBar/>
      <Box component="div" sx={{display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
      <Typography variant="h4" sx={{fontWeight:"bold"}}>NO ANOMALIES</Typography>
      </Box>
    </HomeBackground>
  );
}