import * as React from 'react';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import LoginBack from '../Images/PRT_1.jpg'

export const LoginBackground= styled(Box)({
    backgroundImage: `url(${LoginBack})`,
    height:'100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
});