import axios, { AxiosInstance } from "axios";

export class BackendAPI {
    client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: "http://127.0.0.1:8000",
            headers: {
                "Content-type": "application/json", 
                'Content-Security-Policy': 'upgrade-insecure-requests'
            }
        });
    }

    //Api calls will go here
    //That way we dont need to send headers in every call
    //Just make calls like: this.client.get('/api/users')
    
    getData = () => {
        return this.client.get<any>('test')
            .then((response) => {
                console.log(response.data);
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error);
            })
    }

    login = async (username: string, password: string) => {
        try {
            const response = await this.client.post<any>('login', { username, password });
            console.log("Login successful: " + response.data);
            //Set the JSON web token in local storage
            localStorage.setItem('jwt', response.data.token);
            return response;
        } catch (error) {
            console.log("Login failed: " + error);
            return error;
        }
    }
    
    logout = async () => {
        try {
            const response = await this.client.post<any>('logout');
            console.log("Logout successful: " + response.data);
            //Remove the JSON web token from local storage
            localStorage.removeItem('jwt');
            return response;
        } catch (error) {
            console.log("Logout failed: " + error);
            return error;
        }
    }

    signUp = async (username: string, password: string) => {
        try {
            const response = await this.client.post<any>('signup', { username, password });
            console.log(response.data);
            return response;
        } catch (error) {
            return error;
        }
    }

    forgotPassword = (username: string) => {
        return this.client.post<any>('forgotpassword', {username})
            .then((response) => {
                console.log(response.data);
                return response;
            }).catch((error) => {
                return error;
            })
    }

    addCart = async (cartId: number) => {
        try {
            const response = await this.client.post<any>('addcart', { cartId });
            console.log(response.data);
            return response;
        } catch (error) {
            return error;
        }
    }

    removeCart = async (cartId: number) => {
        try {
            const response = await this.client.post<any>('removecart', { cartId });
            console.log(response.data);
            return response;
        } catch (error) {
            return error;
        }
    }

    getWatchList = async () => {
        try {
            const response = await this.client.get<any>('watchlist');
            console.log(response.data);
            return response;
        } catch (error) {
            return error;
        }
    }
}