import { useEffect, useState } from "react";
import { useBackendClient } from "./hooks/useBackendClient"

export default function TestDataBaseQuery() {

    const [query, setQuery] = useState<string | undefined>(undefined);

    const backendAPI = useBackendClient();
    const getData = async () => {
        const data = await backendAPI.getData();
        setQuery(data.data);
    }

    useEffect(() => {
        console.log("running use effect")
        getData();
    }, [])

    return (
        <div>
            <p>{query}</p>
        </div>
    )

}
