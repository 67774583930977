import * as React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const CustomButton= styled(Button)({
    marginBottom:"15px",
    borderColor: '#eaaa00',
    border:'1.5px solid',
    backgroundColor: '#002856',
    color: '#eaaa00',
    borderRadius: '15px',
    height: '5ch',
    width: '10ch',
    '&:hover': {
        backgroundColor:'#eaaa00',
        color:'#002856',
        borderColor:'#002856'
    }
});