import React, { useState } from 'react';
import img from '../Images/PRT-icon.png';
import accountimg from '../Images/MyAccount.png';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import {Link as RouterLink} from 'react-router-dom';
import AvatarIcon from './avatar';
import Box from '@mui/material/Box';
import PrtIcon from '../Images/PRT-icon.png';
import {NavLinks} from './navlinks';
import {NavText} from './navlinks';

export default function Navbar() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [opacity, setOpacity] = useState(1);
  
  const handleMouseEnter = () => {
    if(!open)
    {
      setOpacity(0.75);
    }
    
  }

  const handleMouseLeave = () => {
    setOpacity(1);
  }
  

  const toggleOpacity = () => {
    setOpacity(prevOpacity => prevOpacity === 1 ? 0.75 : 1);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    toggleOpacity();
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpacity(1);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  
  return (
    <Box sx={{    
      display: 'inline-flex',
      backgroundColor:'#002856',
      color:'#002856',
      height: '10vh',
      minWidth: '100%',
      maxWidth: 'auto',
      position:'sticky',
      top:0,
      zIndex:10
    }}>
      <Box component="img" src={img} alt="Prt Car" sx={{    
        height: '100%',
        width:'auto'
      }}/>
      <Box sx={{
            width:'100%',
            backgroundImage: `url(${PrtIcon})`,
            backgroundRepeat: 'repeat-x',
            backgroundSize: '20vh 10vh'
      }}>
        <Box sx={{
              display: 'inline-flex',
              width:'100%',
              height: '10vh'
        }}>
          <NavLinks href="/home">
            <NavText>Home</NavText>
          </NavLinks>
          <NavLinks href="/watchlist">
            <NavText>WatchList</NavText>
          </NavLinks>
          <NavLinks href= "/anomalies">
            <NavText>Anomalies</NavText>
          </NavLinks>
          <NavLinks style={{opacity}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Stack direction="row" sx={{width:"100%"}}>
                <Button
                  sx={{height:"100%", width:"100%", transition: "opacity 0.3s ease-in-out"}}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <Box component="img" src={accountimg} alt="Account" sx={{    
                    padding:0,
                    minHeight: '5vh',
                    maxHeight: '5vh',
                    width:'auto'
                  }}/>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper sx={{ width:"20vw", flexWrap:'wrap'}}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            sx={{backgroundColor:"#eaaa00", color:"#002856"}}
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem component={RouterLink} to="/myprofile" onClick={handleClose} sx={{flexWrap:'wrap'}}><Box component="div" sx={{width:"7vmin", height:"7vmin", pr:2}}><AvatarIcon/></Box>Profile</MenuItem>
                            <MenuItem component={RouterLink} to="/" onClick={handleClose}>Logout</MenuItem>
                            <MenuItem component={RouterLink} to="/demo" onClick={handleClose}>Demo</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
            </Stack>
          </NavLinks>
        </Box>
      </Box>
    </Box>
  );
}