import * as React from 'react';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';

export default function MyBarChart() {
    const leftLabel = {
        yAxis: [{ label: 'Voltage (VA)' }],
        sx: {
          [`& .${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translateX(-10px)',
          },
        },
      };
      const bottomLabel = {
        xAxis: [{label: 'Time (hr)', data: [1, 2, 3, 5, 8, 10]}],
        sx: {
            [`& .${axisClasses.bottom} .${axisClasses.bottom}`]: {
                transform: 'translateY(-10px)',
            },
        },
      }
      
  return (
    <LineChart
                series={[
                    {
                      data: [2, 5.5, 2, 8.5, 1.5, 5],
                    },
                ]}
                width={500}
                height= {300}
                grid={{ vertical: true, horizontal: true }}
                {...leftLabel}
                {...bottomLabel}
              />
  );
}