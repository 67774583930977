import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
export const PrivateRoutes = () => {
  let auth = {'token':true}
return (
    auth.token ? <Outlet/> : <Navigate to='/'/>
  )
}

//Auth guard may appear to not work but it is because 'auth' is always set to true
//This is because we have no way to check auth at the moment 