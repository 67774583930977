import React from 'react';
import NavBar from '../Components/navbar';
import Box from '@mui/material/Box';
import MapComp from '../Components/map';
import BarChart from '../Components/barchart';
import LineChart from '../Components/linechart';
import { HomeBackground } from '../Components/homebackground';
import Typography from '@mui/material/Typography';

export default function Prt_Hist() {

  return(
      <HomeBackground>
        <NavBar/>
        <Box component="div" sx={{ display:'flex', flexWrap:'wrap', justifyContent: 'center', overflowX:'auto'}}>
          <Box component="div" sx={{ display:'inline', flexWrap:'wrap', justifyContent: 'center'}}>
            <Box sx={{backgroundColor:"white", mb:"50px", mt:"20px"}} width={300} height="fit-content">
            <Typography variant="h5" sx={{fontWeight:"bold", pb:1}}>PRT (#) Current Statistics</Typography>
              <Typography>
                Power (VA):
                <br></br>
                Location:
                <br></br>
                Passengers:
              </Typography>
            </Box>
            <MapComp/>
          </Box>
          <Box component="div" sx={{ display:'inline'}} width={150}></Box>
          <Box component="div" sx={{display:'inline', flexWrap:'wrap', justifyContent: 'center', overflowX:'auto'}}>
            <Box sx={{backgroundColor:"white", borderRadius:"15px", mb:"50px", mt:"20px"}} width={500} height={300}>
              <LineChart/>
            </Box>
            <Box sx={{backgroundColor:"white", borderRadius:"15px", mb:"10px"}} width={500}>
              <BarChart/>
            </Box>
          </Box>
        </Box>
      </HomeBackground>
  );
}