import React from 'react';
import DemoPaper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PasswordBox from '../Components/passwordbox';
import {CustomButton} from '../Components/custombutton';
import {LoginBackground} from '../Components/loginbackground';
import {LoginCard} from '../Components/logincard';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function SignUp() {

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const username = formData.get('username') as string;
        const password = formData.get('password') as string;
        const reenterPassword = formData.get('reenterPassword') as string;
    
        // Now you can use the username and password variables
        console.log('Username:', username);
        console.log('Password:', password);
        console.log('Reenter Password:', reenterPassword);
      };

  return (
    <LoginBackground>
      <LoginCard>
        <DemoPaper sx={{pt:1, pb:1}} square={true} elevation={0}>
          <Typography sx={{fontSize:'larger', pb:2}}><strong>Welcome to the WVU PRT Monitoring App!</strong></Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mx:2, mb:2}}>
                <TextField sx={{width: '35ch' }} id="input-with-sx" label="Username" variant="standard" name="username"/>
            </Box>
            <PasswordBox label="Password" name="password"></PasswordBox>
            <PasswordBox label="Reenter Password" name="reenterPassword"></PasswordBox>
            <CustomButton type="submit">Sign Up</CustomButton>
          </form>
        </DemoPaper>
      </LoginCard>
    </LoginBackground>
  );
}