import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useBackendClient } from '../../hooks/useBackendClient';

const columns: GridColDef<any>[] = [
    { field: 'carNumber', headerName: 'Car Number', width: 100 },
    { field: 'trip', headerName: 'Trip', width: 100 },
    { field: 'date', headerName: 'Date', width: 100 },
    { field: 'start', headerName: 'Start', width: 100 },
    { field: 'end', headerName: 'End', width: 100 },
    { field: 'distance', headerName: 'Distance', type: 'number', width: 100 },
    { field: 'start_time', headerName: 'Start Time', width: 100 },
    { field: 'end_time', headerName: 'End Time', width: 100 },
    { field: 'energyConsumption', headerName: 'Energy Consumption', type: 'number', width: 100 },
    { field: 'passengerNum', headerName: 'Passenger Number', type: 'number', width: 100 },
    { field: 'highestEnergy', headerName: 'Highest Energy', type: 'number', width: 100 },
    { field: 'HET', headerName: 'HET', type: 'number', width: 100 },
];

export default function DataGridDemo() {
    const backendClient = useBackendClient();
    const [rows, setRows] = React.useState([]);
    let i = 0;

    const fetchData = async () => {
        const response = await backendClient.getData();
        const row = response.data.map((subArray: any[]) => ({
            id: i++,
            carNumber: subArray[0],
            trip: subArray[1],
            date: subArray[2],
            start: subArray[3],
            end: subArray[4],
            distance: subArray[5],
            start_time: subArray[6],
            end_time: subArray[7],
            energyConsumption: subArray[8],
            passengerNum: subArray[9],
            highestEnergy: subArray[10],
            HET: subArray[11],
        }));
        setRows(row);
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    );
}

