import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

export default function CustomizedInputBase() {
  return (
    <Box sx={{display:'grid', placeItems:'center'}}>
        <Paper
        component="form"
        sx={{m:"20px", p: '2px 4px', display: 'flex', alignItems: 'center', width:"250px"}}
        >
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Car #"
            inputProps={{ 'aria-label': 'search google maps' }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
        </IconButton>
        </Paper>
    </Box>
  );
}