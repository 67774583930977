import React from 'react';
import NavBar from '../Components/navbar';
import AvatarIcon from '../Components/avatar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PasswordBox from '../Components/passwordbox';
import { HomeBackground } from '../Components/homebackground';
import {CustomButton} from '../Components/custombutton';
import Typography from '@mui/material/Typography';

export default function MyProfile() {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const oldPassword = formData.get('oldPassword') as string;
        const password = formData.get('newPassword') as string;
        const reenterPassword = formData.get('reenterNewPassword') as string;
    
        // Now you can use the username and password variables
        console.log('Old Password:', oldPassword);
        console.log('Password:', password);
        console.log('Reenter Password:', reenterPassword);
      };

  return (
    <HomeBackground>
        <NavBar/>
        <Box component="div" sx={{ display:"inline", justifyContent:"center", textAlign:"center"}}>
            <Box component="div" sx={{borderBottomStyle:"solid", py:2}}>
                <Box component="div" sx={{ display:"inline-flex", height: "100px", width: "100px", justifyContent:"center"}}>
                    <AvatarIcon/>
                </Box>
                <Typography variant="h4" sx={{fontWeight:"bold"}}>John Doe</Typography>
            </Box>
            <Box component="div" sx={{borderBottomStyle:"solid", py:2}}>
            <Typography variant="h5" sx={{fontWeight:"bold"}}>Email</Typography>
                <TextField id="standard-read-only-input" defaultValue="JohnDoe@gmail.com" InputProps={{ readOnly: true }} variant="standard"/>
         </Box>
            <Box component="div" sx={{borderBottomStyle:"solid", py:2}}>
            <Typography variant="h5" sx={{fontWeight:"bold"}}>Change Password</Typography>
                <form>
                    <PasswordBox label="Old Password" name="oldPassword"/>
                    <PasswordBox label="New Password" name="newPassword"/>
                    <PasswordBox label="Reenter New Password" name="reenterNewPassword"/>
                    <CustomButton>Continue</CustomButton>
                </form>
            </Box>
        </Box>
    </HomeBackground>
  );
}