import {BackendClientContext} from "../App";
import {useContext} from "react";

//Hook for accessing the backend API
//Avoids needing to create a new instance of the backend client each time. 
//example: import useBackendClient from '../hooks/useBackendClient'
export const useBackendClient = () => {
    const BackendClient = useContext(BackendClientContext)
    return BackendClient
}
