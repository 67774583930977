import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './Services/RouteGuard';
import { ThemeProvider } from '@mui/material';
import { BackendAPI } from './Services/BackendAPI';
import { createContext, useMemo } from 'react';
import { defaultTheme } from './Services/ThemeProvider';
import React from 'react';
//import logo from './logo.svg';
//import './App.css';
import LoginPage from './Pages/login';
import ForgotPsswd from './Pages/forgotpasswd';
import NavBar from './Components/navbar'; 
import HomePage from './Pages/home';
import PrtHist from './Pages/PRT_History';
import WatchList from './Pages/watchlist';
import AnomaliesPage from './Pages/anomalies';
import MyProfile from './Pages/myprofile';
import StationsPage from './Pages/stations';
import SignUp from './Pages/signup';
import  TestDataBaseQuery  from './TestDatabaseQuery';
import Demo from './Pages/demo';

export const BackendClientContext = createContext<BackendAPI>({} as BackendAPI);


function App() {

  const BackendClient = useMemo(() => {
    return new BackendAPI();
  }, [])

  return (
    <div>
      {/*<div>This is where the app bar should go so it is loaded regardless of route</div>*/}
      {/* Wrap app in backendClientContext so all the components can access the backend client */}
      <BackendClientContext.Provider value={BackendClient}>
        {/* The app also needs to be wrapped in a theme provider using our default theme to see the standard colors we set */}
        <ThemeProvider theme={defaultTheme}>
          <Routes>
            {/* This section is where private routes will go. Basically the routes that require authentication. These are just examples and need to be replaced*/}
            {/* Right route guard just returns true since we have no way of authenticating */}
            <Route element={<PrivateRoutes/>}>
                <Route path ="/car" element={<PrtHist/>} />
                <Route path ="/home" element={<HomePage/>} />
                <Route path = "/testDatabase" element= {<TestDataBaseQuery />} />
                <Route path = "demo" element = {<Demo/> } />
            </Route>

            {/* This section is where public routes will go since it is outside of the route guard. Any route that does not require auth */}
            {/*<Route path = "/?" element={<Navigate to = "/home" />}/>*/}
          <Route path="" element={<LoginPage/>} />
            <Route path= "/forgot-password" element={<ForgotPsswd/>}/>
          <Route path ="/watchlist" element={<WatchList/>} />
            <Route path ="/anomalies" element={<AnomaliesPage/>} />
          <Route path ="/myprofile" element={<MyProfile/>} />
          <Route path ="/signup" element={<SignUp/>} />
          </Routes>
        </ThemeProvider>
      </BackendClientContext.Provider>
    </div>
  );
}

export default App;