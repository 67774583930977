import React from 'react';
import NavBar from '../Components/navbar';
import Box from '@mui/material/Box';
import PrtCard from '../Components/prt_card';
import SearchBar from '../Components/searchbar';
import { HomeBackground } from '../Components/homebackground';

export default function home() {
  return (
    <HomeBackground>
      <NavBar/>
      <SearchBar/>
      <Box component="div" sx={{display:"flex", flexWrap:"wrap"}}>
        <PrtCard/>
      </Box>
    </HomeBackground>
  );
}