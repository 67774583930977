import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListIcon from '@mui/icons-material/List';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import {Link as RouterLink} from 'react-router-dom';

export default function Prt_Card(/*{productId}*/) {

    const [color, setColor] = useState("#002856");
    const [icon, setIcon] = useState(<ListIcon/>);
    const [hover, setHover] = useState("#eaaa00");
    const [text, setText] = useState("ADD");
/*
    //const [card, setCard] = useState(null);

    //Ex. FETCH PRT DATA
    //useEffect(() => {
        //fetchData(productId).then(data => setCard(data));
    }, [productId]);

    const addToList = () => {
        //SEND PRT CARD ID TO USER DB WATCHLIST
        //Ex: 
        //userService.addProductToList(productId.car);
    }
*/
    const handleClick = () => {
      if(color === "#a7001a"){
        setColor("green");
        setIcon(<DoneIcon/>);
        setHover("green");
        setText("REMOVED");

        //removeFromList()

        setTimeout(() => {
          setColor("#002856");
          setIcon(<ListIcon/>);
          setHover("#eaaa00");
          setText("ADD");
      }, 1000);
      }
      else{
        setColor("green");
        setIcon(<DoneIcon/>);
        setHover("green");
        setText("ADDED");

        //addToList()

        setTimeout(() => {
            setColor("#a7001a");
            setIcon(<ListIcon/>);
            setHover("#650103");
            setText("REMOVE");
        }, 1000);
      }
    };

  return (
        <Box component="div" sx={{pl:1, pr:1, m:1, display:"flex", flexWrap:"auto", borderRadius:"15px", backgroundColor:"#eaaa00", justifyContent:"center"}} width="250px">
          <Card sx={{ width:"230px", color: "#002856" }} square={true} elevation={0}>
            <CardMedia
              sx={{ height: 100, backgroundColor:"gray"}}
              image={require('../Images/PRT-icon.png')}
              title="PRT"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                PRT: {/*product.car*/}
              </Typography>
              <Typography variant="body2" color="#002856">
                Current Power: {/*product.voltage*/}
                <br></br>
                Current Location: {/*product.location*/}
                <br></br>
                Current Station: {/*product.station*/}
                <br></br>
                Passenger #: {/*product.passengers*/}
              </Typography>
            </CardContent>
            <CardActions>
              <Button component={RouterLink} to="/car" size="small" variant="contained" sx={{backgroundColor:"#002856", ml:"auto", mr:"auto", "&:hover": {backgroundColor:"#eaaa00"}}}>History</Button>
              <Button size="small" variant="contained" sx={{ml:"auto", mr:"auto", backgroundColor:color, "&:hover": {backgroundColor:hover}}} onClick={handleClick} endIcon={icon}>{text}</Button>
            </CardActions>
          </Card>
        </Box>
  );
}