import * as React from 'react';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';

const otherSetting = {
  height: 300,
  yAxis: [{ label: 'Passengers' }],
  grid: { horizontal: true },
  sx: {
    [`& .${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translateX(-10px)',
    },
  },
};

const dataset = [
    {
        Passengers: 7,
        time: "07:25:13",
        date: '8/24/2024',
    },
    {
        Passengers: 3,
        time: "07:36:13",
        date: '8/24/2024',
    },
    {
        Passengers: 5,
        time: "07:48:13",
        date: '8/24/2024',
    },
    {
        Passengers: 5,
        time: "08:02:02",
        date: '8/24/2024',
    },
    {
        Passengers: 2,
        time: "08:25:37",
        date: '8/24/2024',
    },
    {
        Passengers: 12,
        time: "09:00:13",
        date: '8/24/2024',
    },
    {
        Passengers: 12,
        time: "09:13:13",
        date: '8/24/2024',
    },
    {
        Passengers: 10,
        time: "09:27:54",
        date: '8/24/2024',
    },
    {
        Passengers: 9,
        time: "09:32:59",
        date: '8/24/2024',
    },
    {
        Passengers: 10,
        time: "09:33:30",
        date: '8/24/2024',
    },
    {
        Passengers: 5,
        time: "09:40:21",
        date: '8/24/2024',
    },
    {
        Passengers: 2,
        time: "09:48:29",
        date: '8/24/2024',
    },

];

const valueFormatter = (value: number | null) => `${value} people`;

const updatedDataset = dataset.map(data => {
    const dateTime= `${data.time}\n${data.date}`;

    return {...data, dateTime};
});

export default function MyBarChart() {
  return (
    <BarChart
      dataset={updatedDataset}
      xAxis={[
        {
          scaleType: 'band',
          dataKey: 'dateTime',
          valueFormatter: (people, context) =>
            context.location === 'tick'
              ? `${people}`
              : `${people}`,
        },
      ]}
      series={[{ dataKey: 'Passengers', label: 'Passengers', valueFormatter }]}
      {...otherSetting}
    />
  );
}