import * as React from 'react';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const NavLinks= styled(Link)({
    textAlign:'center',
    alignContent: 'center',
    backgroundColor:'#eaaa00',
    width: '100%',
    textDecoration: 'none',
    color:'inherit',
    display:'flex',
    justifyContent:'center',
    '&:hover': {
        opacity: '0.75'
    }
});

export const NavText= styled(Typography)({
    fontSize:'clamp(2.5vh, 100%, 100%)',
    alignContent:'center',
    height:'100%',
    width:'auto',
    padding:0,
    margin:0,
    fontWeight:700
});