import * as React from 'react';
import * as L from 'leaflet';
import MapMarker from '../Images/map_marker.png';
import Box from '@mui/material/Box';

export interface LeafletProps {
  lat: number;
  long: number;
  zoom: number;
}

async function createLeafletStyles(doc: { getElementById: (arg0: string) => any; createElement: (arg0: string) => any; createTextNode: (arg0: string) => any; head: { appendChild: (arg0: any) => void; }; }) {
  let styles = doc.getElementById('leaflet-css');
  if (styles) {
    return;
  }

  const res = await fetch('https://esm.sh/leaflet/dist/leaflet.css');
  if (!res.ok) {
    throw new Error(`HTTP ${res.status}: ${res.statusText}`);
  }

  const css = await res.text();
  styles = doc.createElement('style');
  styles.id = 'leaflet-css';
  styles.appendChild(doc.createTextNode(css));
  doc.head.appendChild(styles);
}

function Leaflet({ lat, long, zoom }: LeafletProps) {
  const root: any = React.useRef(null);
  const mapRef = React.useRef<any>();
  const [stylesInitialized, setStylesIitialized] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  var southWest = L.latLng(39.628488, -79.977784),
      northEast = L.latLng(39.656380, -79.952189),
      bounds = L.latLngBounds(southWest, northEast);

  var myIcon = L.icon({
    iconUrl: MapMarker,
    iconSize: [15, 10.5],
  });

  React.useEffect(() => {
    const doc = root.current.ownerDocument;
    createLeafletStyles(doc).then(
      () => setStylesIitialized(true),
      (err) => setError(err),
    );
  }, []);

  React.useEffect(() => {
    if (!mapRef.current && stylesInitialized) {
      mapRef.current = L.map(root.current, {maxBounds: bounds});
      L.marker([39.6425, -79.965], {icon: myIcon}).addTo(mapRef.current).bindPopup('PRT #').openPopup();
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        minZoom: 14,
        maxZoom: 19,
        attribution: '© OpenStreetMap',
      }).addTo(mapRef.current);
    }

    if (mapRef.current) {
      mapRef.current.setView([lat, long], zoom);
    }
  }, [stylesInitialized, lat, long, zoom]);

  return (
    <Box sx={{ height: 500, width: 450}}>
      {error ? (
        error.message
      ) : (
        <Box sx={{ width: '100%', height: '100%', zIndex:1  }} ref={root} />
      )}
    </Box>
  );
}

function createComponent<P>(Component: React.FC<P>, args: { argTypes: Record<keyof P, { type: string; defaultValue: any }> }) {
    return (props: Partial<P> = {}) => {
      const finalProps = { ...args.argTypes } as Record<keyof P, any>;
      for (const key in finalProps) {
        if (props[key] !== undefined) {
          finalProps[key] = props[key];
        } else {
          finalProps[key] = finalProps[key].defaultValue;
        }
      }
      return <Component {...(finalProps as P & React.Attributes)} />;
    };
  }

export default createComponent(Leaflet, {
  argTypes: {
    lat: {
      type: 'number',
      defaultValue: 39.6422,
    },
    long: {
      type: 'number',
      defaultValue: -79.965,
    },
    zoom: {
      type: 'number',
      defaultValue: 14,
    },
  },
});

