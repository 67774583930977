import React from 'react';
import DemoPaper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PasswordBox from '../Components/passwordbox';
import {CustomButton} from '../Components/custombutton';
import {LoginBackground} from '../Components/loginbackground';
import {LoginCard} from '../Components/logincard';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useBackendClient } from '../hooks/useBackendClient';


export default function Login() {

  const backendAPI = useBackendClient();
  const [testReponse, setTestReponse] = React.useState<any>({});
  

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;

    const response = backendAPI.login(username, password);
    setTestReponse(response);
       
  }

  return (
    <LoginBackground>
      <LoginCard>
        <DemoPaper sx={{pt:1, pb:1}} square={true} elevation={0}>
          <Typography sx={{fontSize:'larger', pb:2}}><strong>Welcome to the WVU PRT Monitoring App!</strong></Typography>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mx:2, mb:2}}>
                <TextField sx={{width: '35ch', autocomplete: 'username'}} id="input-with-sx" label="Username" variant="standard" name="username"/>
            </Box>
            <PasswordBox label="Password" name="password"></PasswordBox>
            <CustomButton type="submit">Login</CustomButton>
          </form>
          <Link href="/signup"> Sign Up </Link>
          <br></br>
          <Link href="/forgot-password"> Forgot Password </Link>
        </DemoPaper>
      </LoginCard>
    </LoginBackground>
  );
}