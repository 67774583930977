import React from 'react';
import DemoPaper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {CustomButton} from '../Components/custombutton';
import {LoginBackground} from '../Components/loginbackground';
import {LoginCard} from '../Components/logincard';
import Typography from '@mui/material/Typography';
import PasswordBox from '../Components/passwordbox';

export default function ForgotPsswd() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('newPassword') as string;
    const reenterPassword = formData.get('reenterNewPassword') as string;

    // Now you can use the username and password variables
    console.log('Username:', username);
    console.log('Password:', password);
    console.log('Reenter Password:', reenterPassword);
  };

  return (
    <LoginBackground>
      <LoginCard>
      <DemoPaper sx={{pt:1, pb:1, alignContent:"space-evenly"}} square={true} elevation={0}>
        <Typography sx={{fontSize:'larger', pb:2}}><strong>Forgot Password</strong></Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mx:2, mb:2}}>
                <TextField sx={{width: '35ch' }} id="input-with-sx" label="example@gmail.com" variant="standard" name="username"/>
            </Box>
          <PasswordBox label="New Password" name="newPassword"/>
          <PasswordBox label="Reenter Password" name="reenterNewPassword"/>
          <CustomButton type="submit">Continue</CustomButton>
        </form>
        </DemoPaper>
      </LoginCard>
    </LoginBackground>
  );
}